/* https://lospec.com/palette-list/slso8 */
:root {
  --bg-dark: #0D2B45;
  --bg-light: #1f4566;

  --primary: #8D697A;
  --hover: #d08159;
  --active: #FFAA5E;
  --white: #ffecd6;

}

a,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

html {
  overflow: hidden;
  background: var(--bg-dark);
  color: var(--primary);
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important; 
  -webkit-tap-highlight-color: transparent !important;
  margin: 0;
  padding: 0;

  cursor: none;

  font-family: "Inconsolata";
  letter-spacing: .05rem;

  user-select: none;
  touch-action: none
}

#custom-cursor {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--hover);
}

/* body:after {
  content: "";
  position: absolute;
  left: -100vw;
  right: -100vw;
  top: -100vh;
  bottom: -100vh;

  background-image: radial-gradient(var(--bg-light) 1px, transparent 0);
  background-size: 48px 48px;
  background-position: 0px 0px;
  border-radius: 40px;
  transform: rotate(45deg);
  z-index: -1;
} */

h1, h2, h3, h4, h5 {
  margin: 0;
  line-height: 1;
  font-weight: 400;
  user-select: none;
}

h1 { font-size: 16px;}

p {
  margin: .5em 0;
  line-height: 1.25;
  font-weight: 400;
  user-select: none;
}

body {
  font-size: 16px;
  line-height: 1;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;
  overflow: hidden;
  touch-action: none
}

main {
  min-height: 128px;
  min-width: 128px;
  max-width: calc(100vw - 64px);
  padding: 32px;
  background: var(--bg-dark);
  box-shadow: inset 0 0 0 1px var(--primary);

  position: relative;
}

main:after {
  content: "";
  width: 32px;
  height: 32px;
  background: var(--bg-dark);
  border-left: 1px solid var(--primary);
  position: absolute;
  bottom: -16px;
  right: -16px;
  transform: rotate(45deg);
}

.Pad {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  box-shadow: inset 0 0 0 1px var(--primary);
  border-radius: 40px;
  border-radius: 8px;
  /* cursor: pointer; */
  cursor: none;

  outline: none;
  appearance: none;
  border: none;
  background: var(--bg-dark);
  color: inherit;
  font-size: 16px;
  font-family: "Courier" !important;
}

.Pad:hover {
  color: var(--hover);
  box-shadow: inset 0 0 0 1px var(--hover);
}

.Pad:active,
.Pad.active {
  background: var(--active);
  color: var(--white);
  box-shadow: inset 0 0 0 0 var(--primary);
}

#Speaker {
  width: 80px;
  height: 80px;
  transform: rotate(45deg);

  background-image: radial-gradient(var(--active) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: 0px 0px;
  border-radius: 40px;
  filter: drop-shadow(0 0 0 var(--bg-dark));

  opacity: .4;
  transition: opacity 4s ease-out,
              filter 4s ease-out;
}

#Speaker.active {
  opacity: 1;
  filter: drop-shadow(0 0 3px var(--hover));
  transition: opacity 0s ease-out,
              filter 0s ease-out;
}

.video {
  max-width: 1px;
  max-height: 1px;
  opacity: 0;
}

#Control {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 32px;
}

#Control:hover {
  /* cursor: pointer; */
}

#Control #Symbol {
  width: 48px;
  height: 48px;
}

#Control.stopped #Symbol:hover svg,
#Control.stopped #Symbol:active svg{
  /* box-shadow: 0 0 0 1px var(--hover);
  border-radius: 24px; */
}

#Control.stopped #Symbol { opacity: 1; }
#Control.stopped #Symbol svg { pointer-events: none;}
#Control.stopped #Symbol svg path {
  fill: var(--primary);
  transition: fill 1s ease-out,
              filter 1s ease-out;
}

#Control.playing #Symbol { opacity: 1; }
#Control.playing #Symbol svg path {
  fill: var(--white);
  transition: fill .15s ease-out,
              filter .15s ease-out;
}

#Control.stopped {
  filter: drop-shadow(0 0 0 var(--bg-dark));
}
#Control.playing {
  filter: drop-shadow(0 0 6px var(--primary));
}

#info {
  position: absolute;
  top: 16px;
  left: 16px;
  max-width: 30vw;
  min-width: 343px;
}
  #info h1 { 
    display: inline;
    transition: color 1s ease-out;
  }
  #info h1 strong {pointer-events: none; user-select: none;}
  #info p {
    font-size: 14px;
    line-height: 1.5;
    margin: 8px 0;
    opacity: 0;

    transition: opacity .5s ease-out;
  }
  #info em {
    color: var(--hover);
    display: block;
    margin-bottom: 8px;
  }
  #info svg {
    height: 14px;
    overflow: visible;
    margin: 0 8px;
    transform: translateY(2px);
  }
  #info .Tappable-inactive:hover { cursor: help !important; }
  #info .Tappable-inactive:hover h1 { 
    color: var(--active); 
    transition: color 0s ease-out;
  }
  #info .Tappable-inactive:hover + p {
    opacity: 1;
    transition: opacity 0s ease-out;
  }

@media screen and (max-width: 375px) {

  #custom-cursor { display: none; }

  main {
    position: fixed;
    top: 16px;
    right: 16px;
    bottom: 88px;
    left: 16px;
    align-items: center;
    justify-content: center;
    display: flex !important;
    transition: bottom .5s ease-out;
    overflow: hidden;
  }

  main > .Stack {
    position: absolute;
    top: 24px;
    bottom: 32px;
  }

  .Pad {
    width: 96px;
    height: 96px;
    border-radius: 48px;
    border-radius: 8px;
  }

  #wrapper main .controls {
    position: absolute !important;
    bottom: -8px;
  }

  .controls > #Speaker, 
  .controls > #Control {
    width: 96px;
    height: 96px;
    max-width: 96px;
    max-height: 96px;
    border-radius: 48px;
    overflow: hidden;
  }

  .controls #Speaker {
    background-size: 24px 24px;
  }

  #info {
    left: 24px;
    right: 24px;
    bottom: -64px;
    top:unset;
    min-width: unset;
    max-width: unset;

    text-align: center;
    transition: bottom .5s ease-out;
  }

  #info .Tappable-inactive:hover + p {
    opacity: 0;
    transition: opacity .5s ease-out;
  }
  

  #wrapper.active main {
    bottom: 188px;
    transition: bottom .25s ease-out;
  }

  #wrapper.active #info {
    bottom: 32px;
    transition: bottom .25s ease-out;
  }

  #wrapper.active #info h1{
    color: var(--active);
  }

  #wrapper.active #info p {
    opacity: 1 !important;
    transition: opacity .25s ease-out;
  }
}